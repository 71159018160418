<template>
  <section
    class="wrapper relative flex flex-col items-center justify-between rfs:pt-140 h-lg:min-h-svh"
  >
    <div></div>
    <div class="flex flex-col items-center">
      <LogoIcon class="mb-10 shrink-0 rfs:h-106 rfs:w-94" />
      <header class="relative">
        <h1 class="text-app-name font-bold rfs:mb-32">GioPulse</h1>
        <div
          class="pointer-events-none absolute left-full top-1/2 w-[99%] translate-x-[-19.5%] translate-y-[-10.6%]"
        >
          <MediaPicture :src="LogoGradient" :width="897" :height="1008" />
        </div>
      </header>
      <div class="flex items-center justify-center">
        <TextField
          v-model="walletAddress"
          name="walletAddress"
          :placeholder="$t('intro.enterOtherWalletAddress')"
          label-short="wallet address"
          :class-input="'relative rfs:py-17 text-black'"
          :rules="{ required: true, eth: true }"
        />
        ||
        <template v-if="!isConnectedNew">
          <button
            class="btn btn-primary btn-with-icon relative rfs:py-17"
            @click="open()"
          >
            <PlusIcon class="size-24" />
            {{ $t('intro.connectMyWallet') }}
          </button>
        </template>
        <NuxtLink
          v-else
          class="btn btn-primary btn-with-icon relative rfs:py-17"
          :href="localePath('/nodes')"
        >
          {{ $t('intro.myTokens') }}
        </NuxtLink>
      </div>
    </div>
    <div
      class="flex w-104 flex-col items-center text-center text-12 font-bold uppercase leading-[1.66] text-primary rfs:gap-20 rfs:pb-42"
    >
      <LongDownArrowIcon class="rfs:h-66 rfs:w-28" />
      {{ $t('intro.scroll') }}
    </div>
  </section>
</template>

<script setup lang="ts">
import { watchAccount, getAccount, type Config } from '@wagmi/core';
import { useWeb3Modal } from '@web3modal/wagmi/vue';
import { inject, ref } from 'vue';
import { useForm } from 'vee-validate';
import { isAddress } from 'viem';
import LogoGradient from '~/assets/images/LogoGradient.svg';
import MediaPicture from '~/components/Media/MediaPicture.vue';
import LogoIcon from '~/icons/LogoIcon.vue';
import LongDownArrowIcon from '~/icons/LongDownArrowIcon.vue';
import PlusIcon from '~/icons/PlusIcon.vue';
import TextField from '~/components/Form/TextField.vue';

const { open } = useWeb3Modal();
const { defineField } = useForm();
const router = useRouter();
const localePath = useLocalePath();

const wagmiConfig = inject('wagmiConfig');
const isConnectedNew = ref(true);
const { isConnected } = getAccount(wagmiConfig as Config);

if (isConnected) {
  isConnectedNew.value = true;
}

const [walletAddress] = defineField('walletAddress');

watchAccount(wagmiConfig as Config, {
  onChange(data) {
    if (data.status === 'connected') {
      isConnectedNew.value = true;
    } else {
      isConnectedNew.value = false;
    }
  },
});

watch(walletAddress, (newValue) => {
  const isValidAddress = isAddress(newValue);

  if (!isValidAddress) return;

  router.push(`/nodes?address=${newValue}`);
});
</script>
