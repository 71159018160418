<template>
  <p>
    <strong class="text-primary">
      {{ $t('how.step1') }}
    </strong>
  </p>
  <p>
    {{ $t('how.step2') }}
  </p>
</template>
