<template>
  <section
    :id="id"
    class="flex max-w-580 flex-auto flex-col gap-10 rfs:py-186 h-lg:min-h-svh"
  >
    <div ref="contentRef">
      <header class="relative">
        <div
          class="pointer-events-none absolute -left-44 top-1/2 hidden w-4 -translate-y-1/2 bg-primary rfs:h-88 lg:block"
        ></div>
        <h2 ref="triggerRef" class="font-bold leading-normal rfs:text-130">
          {{ $t(title) }}
        </h2>
      </header>
      <div
        :class="[text && 'flex flex-col leading-normal rfs:gap-38 rfs:text-22']"
      >
        <slot />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useHomeSections } from '~/components/Sections/Home/useHomeSections';

const props = defineProps<{ id: string; title: string; text?: boolean }>();

const homeSections = useHomeSections();

const contentRef = ref(null);
const triggerRef = ref(null);

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger);

  gsap.from(triggerRef.value, {
    scrollTrigger: {
      // markers: true,
      trigger: triggerRef.value,
      start: 'top center',
      end: 'top top',
      scrub: true,
      onEnter: () => {
        homeSections.value.activeId = props.id;
      },
      onEnterBack: () => {
        homeSections.value.activeId = props.id;
      },
    },
  });

  gsap.from(contentRef.value, {
    scrollTrigger: {
      // markers: true,
      trigger: triggerRef.value,
      start: 'top bottom',
      end: 'bottom bottom',
      scrub: true,
    },
    opacity: 0,
    y: '10vh',
  });
});
</script>
