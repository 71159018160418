<template>
  <div>
    <h2 class="font-bold text-primary">
      {{ $t('about.gioPulse.title') }}
    </h2>
    <p>
      <strong class="text-primary">
        {{ $t('about.gioPulse.content') }}
      </strong>
    </p>
  </div>
  <div>
    <h2 class="font-bold">
      {{ $t('about.worldFirst.title') }}
    </h2>
    <p>
      {{ $t('about.worldFirst.content') }}
    </p>
  </div>
</template>
