<template>
  <article
    v-for="(item, index) in items"
    :key="index"
    class="relative border-b-1 border-gray-393939 leading-normal rfs:text-22"
  >
    <div
      v-if="selectedIndex === index"
      class="pointer-events-none absolute -left-44 top-1/2 hidden h-full w-4 -translate-y-1/2 bg-gray-212121 lg:block"
    ></div>
    <header
      class="flex cursor-pointer justify-between rfs:gap-30 rfs:py-12"
      @click="() => (selectedIndex = index)"
    >
      <h3 class="font-bold text-primary">
        {{ $t(`faq.${item}.question`) }}
      </h3>
      <ArrowRightIcon
        :class="[
          'mt-4 size-20 self-start transition-transform',
          selectedIndex === index && 'rotate-90 text-primary',
        ]"
      />
    </header>
    <div v-show="selectedIndex === index" class="pt-8 rfs:pb-36">
      <template v-if="item === 'connectWallet'">
        <ul>
          <li v-for="step in 7" :key="step">
            <span class="font-bold">
              {{ $t(`faq.${item}.answer.step${step}.title`) }}
            </span>
            :
            {{ $t(`faq.${item}.answer.step${step}.content`) }}
          </li>
        </ul>
      </template>
      <template v-else-if="item === 'receivingAddress'">
        <p>{{ $t(`faq.${item}.answer.options`) }}</p>
        <br />
        <p>{{ $t(`faq.${item}.answer.recommend`) }}</p>
        <br />
        <h3 class="font-bold">{{ $t(`faq.${item}.answer.attention`) }}</h3>
        <p>{{ $t(`faq.${item}.answer.seed`) }}</p>
        <br />
        <p>{{ $t(`faq.${item}.answer.glamster`) }}</p>
      </template>
      <p v-else>{{ $t(`faq.${item}.answer`) }}</p>
    </div>
  </article>
</template>

<script lang="ts" setup>
import ArrowRightIcon from '~/icons/ArrowRightIcon.vue';

// const items = [
//   {
//     q: 'Wcing elit, sed do eiusmod tempor incididunt ut labore?',
//     a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e',
//   },
//   {
//     q: 'Excepteur sint occaecat cupidatat non proident, sunt?',
//     a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e',
//   },
//   {
//     q: 'Sed ut perspiciatis unde omnis istr sit voluptatem accus?',
//     a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e',
//   },
//   {
//     q: 'Wcing elit, sed do eiusmod tempor incididunt ut labore?',
//     a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e',
//   },
// ];

const items = [
  'connectWallet',
  'receivingAddress',
  'slotNumber',
  'slotNumberImportant',
  'serverIP',
  'serverIPImportant',
  'node',
  'activeStatus',
  'onlineStatus',
  'nft',
  'minting',
  'earn',
  'gioNFTGood',
  'swap',
  'send',
  'usability',
];

const selectedIndex = ref(0);
</script>
